import React, { useState,useEffect } from 'react';

import axios from 'axios';

import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

const LogOut = ({setIsLoggedIn}) => {
    const [isLoaded,setIsLoaded]=useState(false)
  
  const navigate = useNavigate(); // Use useNavigate instead of useHistory
  useEffect(() => {
    const checkLogoutStatus = async () => {
      try {
        axios.defaults.withCredentials = true;
        const response = await axios.get('/logout');
        navigate('/')
        setIsLoggedIn(false)
      } catch (err) {
       
      }
      setIsLoaded(true)
     
    };
  
    checkLogoutStatus();
  }, []);

  return isLoaded && (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-red-600 mb-4">Network Error</h1>
        <p className="text-lg text-gray-700">Logout Failed</p>
      </div>
    </div>
  );
}

export default LogOut