import React, { useState, useEffect } from "react";
import "./Admin.css";
import axios from "axios";
import Passbook from "../Passbook/Passbook";
import Modal from "react-modal"
import { useNavigate } from "react-router-dom";

const Admin = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedName, setSelectedName] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Fetch users from the backend
    const fetchUsers = async () => {
      try {
        axios.defaults.withCredentials = true;
        const response = await axios.get("/admin/clients");
        const data = response.data
        setUsers(data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const handlePassbookClick = (userId, name) => {
    setSelectedName(name);
    setSelectedUser(userId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };
  function sumPassbookAmounts(passbook) {
    // Check if passbook is an array and has at least one element
    if (Array.isArray(passbook) && passbook.length > 0) {
      // Reduce the passbook array to calculate the sum of amounts
      const sum = passbook.reduce((total, entry) => {
        // Parse the amount as an integer
        const amount = parseInt(entry.amount, 10);

        // Check if the amount is a valid number
        if (!isNaN(amount)) {
          return total + amount;
        } else {
          return total;
        }
      }, 0);

      return sum;
    } else {
      // If passbook is not an array or is an empty array, return 0
      return 0;
    }
  }

  function calculateLumpsumFutureValue(OneTimeInvestment, returnRate, timePeriod) {
    const yearlyInterestRate = returnRate / 100;
    const totalPeriods = timePeriod;

    const futureValue =
      OneTimeInvestment * Math.pow(1 + yearlyInterestRate, totalPeriods);
      let roundedValue=futureValue.toFixed(2)

    return parseFloat(roundedValue,10); // Round to 2 decimal places
  }

  function calculateDaysFromDate(givenDate) {
    // Parse the given date string to a Date object
    const startDate = new Date(givenDate);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const timeDifference = currentDate - startDate;

    // Convert milliseconds to days and months
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));


    return daysDifference / 365.0;
  }
  function monthDiff(date1, date2) {
    const yearDiff = date2.getFullYear() - date1.getFullYear();
    const monthDiff = date2.getMonth() - date1.getMonth();
    const totalMonths = yearDiff * 12 + monthDiff;

    return totalMonths;
  }

  function daysOverMonth(date1, date2) {
    const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
    
    const totalDays = Math.round(Math.abs((date2 - date1) / oneDay));

    return totalDays/ 365.0;
  }

  function calculateSIPFutureValue(monthlyInvestment, returnRate, timePeriod) {
    const monthlyInterestRate = returnRate / 1200;
    const totalPeriods = timePeriod;

    const futureValue =
      monthlyInvestment *
      (((Math.pow(1 + monthlyInterestRate, totalPeriods) - 1) * (1 + monthlyInterestRate)) / monthlyInterestRate);
    let roundedValue=futureValue.toFixed(2)
    return parseFloat(roundedValue,10); // Round to 2 decimal places
  }

  const calculateSipFutureValue = (startDate, amount, totalInstallment) => {
 
    var monthvalue = 0
    let completedMonths = monthDiff(new Date(startDate), new Date())
    if (completedMonths <= totalInstallment) {
      monthvalue = calculateSIPFutureValue(amount, 24, completedMonths)

      monthvalue += parseInt((totalInstallment - completedMonths) * amount,10)
      
      const startDateNextMonth = new Date(startDate);
      startDateNextMonth.setMonth(startDateNextMonth.getMonth() + completedMonths);
      let remainingDays = daysOverMonth(startDateNextMonth, new Date())
      if(remainingDays>0)
          monthvalue=calculateLumpsumFutureValue(monthvalue,24,remainingDays)
      
    }
    else {
      monthvalue = calculateSIPFutureValue(amount, 24, totalInstallment)
      
      const startDateNextMonth = new Date(startDate);
      startDateNextMonth.setMonth(startDateNextMonth.getMonth() + totalInstallment);
      let remainingDays = daysOverMonth(startDateNextMonth, new Date())
      if(remainingDays>0)
      monthvalue=calculateLumpsumFutureValue(monthvalue,24,remainingDays)

    }
    return monthvalue
  }

  const handleRegisterClick = () => {
    navigate("/register"); // Navigate to the "/register" route when the button is clicked
  };

  return (
    <div className="admin-container">
      <h2>Admin Dashboard</h2>
      <button onClick={handleRegisterClick}>Register New User</button>
      <table>
        <thead>
          <tr>
            <th>Image</th>
            <th>Name</th>
            <th>Folio No.</th>
            <th>Total Investment</th>
            <th>Portfolio Value</th>
            <th>Investment Type</th>
           
            <th>Passbook</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.userId}>
              <td>
                <img
                  src={`data:image/jpeg;base64,${user.userData.imagePreview}`}
                  alt={`${user.userData.name}'s Profile`}
                  className="user-image"
                />
              </td>
              <td>{user.userData.name}</td>
              <td>{user.userData.folioNo}</td>
              <td>₹{sumPassbookAmounts(user.passbook)}</td>
              <td>₹{user.userData.investmentType == "lumpsum" ? calculateLumpsumFutureValue(user.userData.investmentValue, 24, calculateDaysFromDate(user.userData.startDate)) : calculateSipFutureValue(user.userData.startDate,user.userData.investmentValue,user.userData.totalInstallmentPaid)}</td>
              <td>{user.userData.investmentType}</td>
              <td>
                <button onClick={() => handlePassbookClick(user.userId, user.userData.name)}>
                  View Passbook
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Passbook"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <button className="modal-close" onClick={closeModal}>
          &times;
        </button>
        {selectedUser && <Passbook userId={selectedUser} name={selectedName} />}
      </Modal>
    </div>
  );
};

export default Admin;