// Card.js
import React, { useState,useEffect } from 'react';
import profile from './profile.png'
import axios from 'axios';
import Modal from './Modal'
import { useNavigate } from 'react-router-dom';


function calculateLumpsumFutureValue(OneTimeInvestment, returnRate, timePeriod) {
   const yearlyInterestRate = returnRate / 100;
   const totalPeriods = timePeriod;

   const futureValue =
   OneTimeInvestment * Math.pow(1 + yearlyInterestRate, totalPeriods);

   return futureValue.toFixed(2); // Round to 2 decimal places
}

function calculateDaysFromDate(givenDate) {
   // Parse the given date string to a Date object
   const startDate = new Date(givenDate);
 
   // Get the current date
   const currentDate = new Date();
 
   // Calculate the difference in milliseconds
   const timeDifference = currentDate - startDate;
 
   // Convert milliseconds to days and months
   const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
   
 
   return daysDifference/365.0;
 }
 
 function monthDiff(date1, date2) {
   const yearDiff = date2.getFullYear() - date1.getFullYear();
   const monthDiff = date2.getMonth() - date1.getMonth();
   const totalMonths = yearDiff * 12 + monthDiff;

   return totalMonths;
 }

 function daysOverMonth(date1, date2) {
   const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
   
   const totalDays = Math.round(Math.abs((date2 - date1) / oneDay));

   return totalDays/ 365.0;
 }

 function calculateSIPFutureValue(monthlyInvestment, returnRate, timePeriod) {
   const monthlyInterestRate = returnRate / 1200;
   const totalPeriods = timePeriod;

   const futureValue =
     monthlyInvestment *
     (((Math.pow(1 + monthlyInterestRate, totalPeriods) - 1) * (1 + monthlyInterestRate)) / monthlyInterestRate);
   let roundedValue=futureValue.toFixed(2)
   return parseFloat(roundedValue,10); // Round to 2 decimal places
 }

 function sumPassbookAmounts(passbook) {
   // Check if passbook is an array and has at least one element
   if (Array.isArray(passbook) && passbook.length > 0) {
     // Reduce the passbook array to calculate the sum of amounts
     const sum = passbook.reduce((total, entry) => {
       // Parse the amount as an integer
       const amount = parseInt(entry.amount, 10);

       // Check if the amount is a valid number
       if (!isNaN(amount)) {
         return total + amount;
       } else {
         return total;
       }
     }, 0);

     return sum;
   } else {
     // If passbook is not an array or is an empty array, return 0
     return 0;
   }
 }

 const calculateSipFutureValue = (startDate, amount, totalInstallment) => {

   var monthvalue = 0
   let completedMonths = monthDiff(new Date(startDate), new Date())
   if (completedMonths <= totalInstallment) {
     monthvalue = calculateSIPFutureValue(amount, 24, completedMonths)

     monthvalue += parseInt((totalInstallment - completedMonths) * amount,10)
     
     const startDateNextMonth = new Date(startDate);
     startDateNextMonth.setMonth(startDateNextMonth.getMonth() + completedMonths);
     let remainingDays = daysOverMonth(startDateNextMonth, new Date())
     
     if(remainingDays>0)
         monthvalue=calculateLumpsumFutureValue(monthvalue,24,remainingDays)
     
   }
   else {
     monthvalue = calculateSIPFutureValue(amount, 24, totalInstallment)
     
     const startDateNextMonth = new Date(startDate);
     startDateNextMonth.setMonth(startDateNextMonth.getMonth() + totalInstallment);
     let remainingDays = daysOverMonth(startDateNextMonth, new Date())
     
     if(remainingDays>0)
     monthvalue=calculateLumpsumFutureValue(monthvalue,24,remainingDays)

   }
   return monthvalue
 }

const Card = ({ investedValue, realizedReturn, portfolioValue }) => {
   const navigate = useNavigate();
   const [details,setDetails]=useState(null)
   const [isModalOpen, setIsModalOpen] = useState(false);
   const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
   
   const [isLoaded,setIsLoaded]=useState(false)
   const [dataP,setData]=useState(null)
   const [portV,setPortV]=useState(0)

   const profileData = {
      photoUrl: '/download-profile', // Replace with actual photo URL
      accountNo: '123456789',
      mobileNo: '9876543210',
      panNo: 'ABCDE1234F',
      address: '123 Main St, City, Country',
      name: "Ravi Nishad"
   };

   useEffect(() => {
      const fetchData = async () => {
        try {
          // Make an Axios GET request to your server
          axios.defaults.withCredentials = true;
          const response = await axios.get('/data');
          setIsLoaded(true)
          setDetails({
            name:response.data.name,
            'Folio No':response.data.folioNo,
            'Investment Type':response.data.investmentType,
            'Start Date':response.data.startDate,
            'Date of Birth':response.data.dob,
            'Mobile No.':response.data.mobile,
            Address:response.data.address
          })
          setData(response.data)
          
          
        } catch (error) {

         navigate('/login');
          console.error('Error fetching data:', error.message);
        }
      };
  
      // Call the fetchData function when the component mounts
      fetchData();
    }, []);

    useEffect(()=>{
      if(dataP)
      setPortV(dataP.investmentType=="sip"?calculateSipFutureValue(dataP.startDate,dataP.investmentValue,dataP.totalInstallmentPaid):calculateLumpsumFutureValue(dataP.investmentValue,24,calculateDaysFromDate(dataP.startDate)))
    },[dataP])

   const data={
      "portfolioValue":"Rs 5000",
      "investedValue":"Rs4000",
      "totalReturn":"Rs 1000",
   }
   return (
      isLoaded &&

      (<div>
         <div style={{  backgroundColor:'#2f024d',display:'flex',alignItems:'center',justifyContent:'center',paddingBottom:'60px',paddingTop:'60px'}}>
         <div style={{  backgroundColor: 'white', width:'90vw', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.5)',paddingTop:'20px',paddingBottom:'20px'}}>


            <div className="container mx-auto">
               <div className="lg:flex">
                  {/* Column 1 - Desktop view */}
                  <div className="lg:w-1/2" style={{padding: '20px'}}>
                     <div style={{  display: 'flex', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.5)', borderRadius: '5px',  border: '1px solid #ddd' ,padding:'5px',flexDirection:'column',alignItems:'center'}}>
                        <div style={{ flex: '1/2' }}>
                        
                           <img className='imgP' style={{ maxWidth: '70px', borderRadius: '40px' }} src={`data:image/jpeg;base64,${dataP.imagePreview}`} alt="Profile" />
                        </div>

                        <div style={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'left', fontWeight: 'bold' }}>
                           <p>{dataP.name}</p>
                           <p>Folio No.: {dataP.folioNo}</p>
                        </div>
                     </div>

                  </div>



                  {/* Column 2 - Desktop view */}

                  <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div style={{color:'black'}}>
        <div className="max-w-lg mx-auto mt-8 bg-white shadow-md rounded-md overflow-hidden">
        <table className='w-full overflow-hidden'>
  <tbody className="divide-y divide-black-200">
    {Object.entries(details).map(([key, value]) => (
      <tr className="border border-slate-300 " key={key}>
        <td className='bg-gray-200 px-2'>{key}</td>
        <td  className="px-3 py-4 break-normal">{value}</td>
      </tr>
    ))}
  </tbody>
</table>

    </div>
          <button onClick={closeModal} className="mt-4 bg-gray-300 hover:bg-gray-400 py-2 px-4 rounded-md">
            Close
          </button>
        </div>
      </Modal>
                  <div className="lg:w-1/2" >
                  <div style={{display:"flex",flex:1}}>
                  <div style={{ textAlign:'center',display: 'flex',flex:'1' ,flexDirection: 'column', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.5)', borderRadius: '5px', margin: '20px', border: '1px solid #ddd' }}>
                           <p style={{ backgroundColor: '#0f848a',padding:'5px',fontWeight:'bold',color:'white'}}>Portfolio Value</p>
                           <div style={{alignItems:'center',flex:'1',display:'flex',justifyContent:'center',paddingTop:'30px',paddingBottom:'30px' ,fontWeight:'bold'}}>
                           <p >₹{portV}</p>
                           </div>
                        </div>
                        <div style={{ textAlign:'center',display: 'flex',flex:'1' ,flexDirection: 'column', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.5)', borderRadius: '5px', margin: '20px', border: '1px solid #ddd' }}>
                           <p style={{ backgroundColor: '#0f848a',padding:'5px',fontWeight:'bold',color:'white'}}>Invested Value</p>
                           <div style={{alignItems:'center',flex:'1',display:'flex',justifyContent:'center',paddingTop:'30px',fontWeight:'bold',paddingBottom:'30px'}}>
                           <p >₹{parseInt(dataP.investmentValue,10)*parseInt(dataP.totalInstallmentPaid,10)}</p>
                           </div>
                        </div>
                     </div>
                     <div style={{display:"flex",flex:1}}>
                        <div style={{ textAlign:'center',display: 'flex',flex:'1' ,flexDirection: 'column', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.5)', borderRadius: '5px', margin: '20px', border: '1px solid #ddd' }}>
                           <p style={{ backgroundColor: '#0f848a',padding:'5px',fontWeight:'bold',color:'white'}}>Total Return</p>
                           <div style={{alignItems:'center',flex:'1',display:'flex',justifyContent:'center',paddingTop:'30px',paddingBottom:'30px',fontWeight:'bold'}}>
                           <p >₹{(portV-(parseInt(dataP.investmentValue,10)*parseInt(dataP.totalInstallmentPaid,10))).toFixed(2)}</p>
                           </div>
                        </div>
                        <div style={{ textAlign:'center',display: 'flex',flex:'1' ,flexDirection: 'column', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.5)', borderRadius: '5px', margin: '20px', border: '1px solid #ddd' }}>
                           <p style={{ backgroundColor: '#0f848a',padding:'5px',fontWeight:'bold',color:'white'}}>Passbook</p>
                           <div style={{alignItems:'center',flex:'1',display:'flex',justifyContent:'center',paddingTop:'30px',paddingBottom:'30px'}}>
                           <p >Comming Soon...</p>
                           </div>
                        </div>
                     </div>
                     <div style={{display:"flex",flex:1}}>
                        <div style={{ textAlign:'center',display: 'flex',flex:'1' ,flexDirection: 'column', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.5)', borderRadius: '5px', margin: '20px', border: '1px solid #ddd' }}>
                           <p style={{ backgroundColor: '#0f848a',padding:'5px',fontWeight:'bold',color:'white'}}>Account Details</p>
                           <div style={{alignItems:'center',flex:'1',display:'flex',justifyContent:'center',paddingTop:'30px',paddingBottom:'30px'}}>
                           <button onClick={openModal} className="bg-blue-500 text-white py-2 px-4 rounded-md">
        Open
      </button>
                           </div>
                        </div>
                        <div style={{ textAlign:'center',display: 'flex',flex:'1' ,flexDirection: 'column', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.5)', borderRadius: '5px', margin: '20px', border: '1px solid #ddd' }}>
                           <p style={{ backgroundColor: '#0f848a',padding:'5px',fontWeight:'bold',color:'white'}}>Trading Guide</p>
                           <div style={{alignItems:'center',flex:'1',display:'flex',justifyContent:'center',paddingTop:'30px',paddingBottom:'30px'}}>
                           <p >Comming Soon...</p>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               {/* Single column for mobile view */}
            </div>
         </div>

         

         </div>

      </div>)
   );
};

export default Card;
