// src/components/Form.js

import React, { useState } from 'react';
import axios from 'axios';

const Register = () => {
  const [formData, setFormData] = useState({
    username: '',
    name: '',
    dob: '',
    adharNo: '',
    panNo: '',
    mobile: '',
    address: '',
    investmentType: '',
    investmentValue: '',
    startDate: '',
    imagePreview:null,
    image: null, // New field for image upload
  });

  const handleChange = (e) => {
    if (e.target.type === 'file') {
      const file = e.target.files[0];
      setFormData({
        ...formData,
        [e.target.name]: file,
        imagePreview: URL.createObjectURL(file), // Add image preview URL
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formDataToSend = new FormData();

      // Append form data to FormData object
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }

      // Send a POST request to the server
      axios.defaults.withCredentials = true;
      const response = await axios.post('/register', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };



  return (
    <div className="max-w-lg mx-auto mt-8 p-4 bg-white shadow-md rounded-md">
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-sm font-semibold text-gray-600">Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold text-gray-600">DOB:</label>
          <input
            type="date"
            name="dob"
            value={formData.dob}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold text-gray-600">Adhar No.:</label>
          <input
            type="text"
            name="adharNo"
            value={formData.adharNo}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold text-gray-600">Pan No.:</label>
          <input
            type="text"
            name="panNo"
            value={formData.panNo.toLocaleUpperCase()}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold text-gray-600">Mobile:</label>
          <input
            type="text"
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold text-gray-600">Address:</label>
          <textarea
            name="address"
            value={formData.address}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded-md"
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold text-gray-600">Investment Type:</label>
          <div className="flex">
            <label className="mr-4">
              <input
                type="radio"
                name="investmentType"
                value="sip"
                checked={formData.investmentType === 'sip'}
                onChange={handleChange}
              />
              SIP
            </label>
            <label>
              <input
                type="radio"
                name="investmentType"
                value="lumpsum"
                checked={formData.investmentType === 'lumpsum'}
                onChange={handleChange}
              />
              Lumpsum
            </label>
          </div>
        </div>

        {/* New Input for Investment Value */}
        <div className="mb-4">
          <label className="block text-sm font-semibold text-gray-600">Investment Value:</label>
          <input
            type="text"
            name="investmentValue"
            value={formData.investmentValue}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded-md"
          />
        </div>

        {/* New Date Picker for Start Date */}
        <div className="mb-4">
          <label className="block text-sm font-semibold text-gray-600">Start Date:</label>
          <input
            type="date"
            name="startDate"
            value={formData.startDate}
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded-md"
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-semibold text-gray-600">Upload Image:</label>
          <input
            type="file"
            accept="image/*"
            name="image"
            onChange={handleChange}
            className="mt-1 p-2 w-full border rounded-md"
          />
        </div>
        {formData.image && (
            <img
              src={formData.imagePreview}
              alt="Image Preview"
              style={{ maxWidth: '200px', maxHeight: '200px', marginTop: '10px' }}
            />
          )}
        <button
          type="submit"
          style={{backgroundColor:'black'}}
          className="bg-black-500 text-white py-2 px-4 rounded-md"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default Register;
