// Home.js

import React, { useRef, useState } from 'react';
import Middle from '../Middle/Middle';
import About from '../About/About';
import Team from '../Team/Team';
import Contact from '../Contact/Contact';
import SIPCalculator from '../SIPCalculator/SIPCalculator';
const Home = ({scrollAboutRef,scrollContactRef,scrollTeamRef}) => {
  
  return (
    <div>
      <Middle />
      <div className='belowMain'>
      
                <p className="belowMain1" style={{paddingTop:'20px',fontSize:'4vw',fontWeight:'bold',color:'#801c99'}}>Are you Looking for Investment to get better Returns ?</p>
                <p className="belowMain2" style={{fontSize:'4vw'}}>Register For More Details</p>
                <p className="belowMain3" style={{fontSize:'4vw'}}>Invest Now For Higher Return</p>
      </div>
      <SIPCalculator/>
      <About scrollTargetRef={scrollAboutRef} />
      <Team scrollTargetRef={scrollTeamRef}/>
      <Contact scrollTargetRef={scrollContactRef} />

    </div>
  );
};

export default Home;
