
import './styles/tailwind.css';
import './App.css'
import React,{useRef,useState,useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Component/Home/Home';
import Login from './Component/Login/Login';
import Profile from './Component/Profile/Profile'
import Header from './Component/Header/Header';
import Register from './Component/Register/Register'
import axios from 'axios';
import LogOut from './Component/LogOut/LogOut';
import Admin from './Component/Admin/Admin'



function App() {
  const scrollAboutRef = useRef(null);
  const [isAbout,setAbout]=useState(false)
  const [isContact,setContact]=useState(false)
  const [isTeam,setTeam]=useState(false)
  const [isLoaded,setIsLoaded]=useState(false)
  const [isLoggedIn,setIsLoggedIn]=useState(false)

  const scrollToAbout = () => {
    setAbout(true)
  };

  const scrollTeamRef = useRef(null);

  const scrollToTeam = () => {
    setTeam(true)
    
  };

  const scrollContactRef = useRef(null);

  const scrollToContact = () => {
    setContact(true)
  };

  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        axios.defaults.withCredentials = true;
        const response = await axios.get('/login_status');
        setIsLoggedIn(true);
      } catch (err) {
        setIsLoggedIn(false);
      }
      setIsLoaded(true);
    };
  
    checkLoginStatus();
  }, []);
  
  useEffect(() => {
    // Scroll to the div after it has rendered
    if (scrollAboutRef.current && isAbout) {
      scrollAboutRef.current.scrollIntoView({ behavior: 'smooth' });
      setAbout(false)
    }
    if (scrollContactRef.current && isContact) {
      scrollContactRef.current.scrollIntoView({ behavior: 'smooth' });
      setContact(false)
    }
    if(scrollTeamRef.current && isTeam){
      scrollTeamRef.current.scrollIntoView({ behavior: 'smooth' });
      setTeam(false)
    }
  }, [isAbout,isContact,isTeam]);
  return (
    
      isLoaded && (
        <Router>
          <Header
            scrollToAbout={scrollToAbout}
            scrollToTeam={scrollToTeam}
            scrollToContact={scrollToContact}
            isLoggedIn={isLoggedIn}
          />
          <Routes>
            <Route
              path="/"
              element={<Home scrollAboutRef={scrollAboutRef} scrollTeamRef={scrollTeamRef} scrollContactRef={scrollContactRef} />}
            />
            <Route
              path="/about"
              element={<Home scrollAboutRef={scrollAboutRef} scrollTeamRef={scrollTeamRef} scrollContactRef={scrollContactRef} />}
            />
            <Route
              path="/contact"
              element={<Home scrollAboutRef={scrollAboutRef} scrollTeamRef={scrollTeamRef} scrollContactRef={scrollContactRef} />}
            />
            <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn}/>} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/admin" element={<Admin/>} />
            <Route path="/register" element={<Register isLoggedIn={isLoggedIn}/>} />
            <Route path="/lout" element={<LogOut setIsLoggedIn={setIsLoggedIn}/>} />
          </Routes>
        </Router>
      )
    );
}

export default App;
