// Home.js

import React from 'react';
import ProfileHeader from '../Header/ProfileHeader';
import CardsList from '../CardList';
import Card from '../Card';


const Home = () => {
    return (
        <div>
           <Card
          investedValue="$10,000"
          realizedReturn="$2,500"
          portfolioValue="$12,500"
        />
        </div>
    );
};

export default Home;
