import React from 'react';
import "./Contact.css"
import { FaEnvelope, FaEnvelopeOpen, FaPhone, FaPhoneSquare } from 'react-icons/fa';


const Contact = ({scrollTargetRef}) => {
    
    return (

        <div ref={scrollTargetRef} style={{display:'flex',alignItems:'center',flexDirection:'column',backgroundColor:'#0a052e',color:'white'}}>
<div style={{paddingTop:'20px',fontSize:'30px',fontWeight:'bold'}}>Contact Us</div>
        <div className='flex flex-wrap' style={{justifyContent:'center',width:'calc(100% - 3vw - 30px)'}}>
            <div className="w-full md:w-1/2 lg:w-1/2 " style={{padding:'20px',display:'flex',textAlign:'center',alignItems:'center',flexDirection:'column'}}>
               <FaPhone size={26} color="white" style={{ transform: 'scaleX(-1)' }} />
               {/*<p>+91-9096563699 / +91-8600243900 / +91-9156336552</p>*/}
               <p>+91-9096563699</p>
                </div>
                <div className="w-full md:w-1/2 lg:w-1/2 " style={{padding:'20px',display:'flex',textAlign:'center',alignItems:'center',flexDirection:'column'}}>
                <FaEnvelope size={26} color="white" style={{ transform: 'scaleX(-1)' }} />
               <p>rdssolutionservices@gmail.com</p>
                </div>
                
        </div>
        </div>
        
    );
};

export default Contact;