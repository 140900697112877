const Modal = ({ isOpen, onClose, children }) => {
  const overlayClasses = isOpen ? 'fixed inset-0' : 'hidden';
  const modalClasses = isOpen ? 'opacity-100 fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white' : 'hidden';

  return (
    <div className={overlayClasses} style={{backgroundColor:'#00000099'}} onClick={onClose}>
      <div className={modalClasses} style={{backgroundColor:'white',width:'80vw',maxHeight:'80vh',maxWidth:'500px',margin:'10px',padding:'10px', overflowY:'scroll'}} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );

};

export default Modal