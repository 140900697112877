import React, { useState } from 'react';
import "./Team.css"
import ravi from './ravi.png'
import sanjeev from './sanjeev.jpeg'
import deepak from './deepak.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';

const Team = () => {
    const [showTeam,setShowTeam]=useState(false)
    return (
        <div style={{display:'flex',alignItems:'center',flexDirection:'column'}} onClick={()=>{setShowTeam(!showTeam)}}>
<div className='teamCard' style={{fontSize:'30px',fontWeight:'bold'}}>Our Team {showTeam?<FontAwesomeIcon style={{paddingLeft:'20px'}} icon={faAngleUp} />:<FontAwesomeIcon style={{paddingLeft:'20px'}} icon={faAngleDown} />}</div>
        {showTeam&&(<div className='flex flex-wrap teamCard' style={{justifyContent:'center',width:'calc(100% - 3vw - 30px)'}}>
            <div className="w-full md:w-1/2 lg:w-1/3 teamCard1" style={{padding:'20px',display:'flex',textAlign:'center',alignItems:'center',flexDirection:'column'}}>
               <img style={{borderRadius:'50%',maxWidth:'30vw'}}src={ravi}></img>
               <p>Managing Director & Chief Executive Officer</p>
               <p style={{fontWeight:'bold'}}>Mr. Ravi Nishad</p>
                </div>
                {/* 
                <div className="w-full md:w-1/2 lg:w-1/3 teamCard1" style={{padding:'20px',display:'flex',textAlign:'center',alignItems:'center',flexDirection:'column'}}>
               <img style={{borderRadius:'50%',maxWidth:'30vw'}}src={deepak}></img>
               <p>Executive Director & Chief Risk Officer</p>
               <p style={{fontWeight:'bold'}}>Mr. Deepak Kharwar</p>
                </div>
                <div className="w-full md:w-1/2 lg:w-1/3" style={{padding:'20px',display:'flex',textAlign:'center',alignItems:'center',flexDirection:'column'}}>
               <img style={{borderRadius:'50%',maxWidth:'30vw'}}src={sanjeev}></img>
               <p>Chief Financial Officer</p>
               <p style={{fontWeight:'bold'}}>Mr. Sanjeev Chauhan</p>
                </div>
                */}
                
        </div>)}
        </div>
        
    );
};

export default Team;