import React from 'react';
import "./About.css"

const About = ({scrollTargetRef}) => {
    return (
        <div ref={scrollTargetRef} style={{display:'flex',alignItems:'center',flexDirection:'column'}}>
<div style={{paddingTop:'20px',fontSize:'35px',fontWeight:'bold',color:'#eb6d1e'}}>About Us</div>
        <div className='flex flex-wrap' style={{padding:'3vw'}}>
            <div className="md:w-1/2 lg:w-1/3 aboutCard" style={{padding:'10px'}}>
                <h1 style={{textAlign:'center',fontSize:'30px',color:'#ccc918'}}>Who We Are</h1>
                 <p style={{textAlign:'justify'}}>Innovative and client-focused, our company is dedicated to navigating the dynamic financial landscape with expertise and integrity. At the core of our identity is a commitment to delivering optimal results for our investors. We're a unique blend of SIP investing and real estate expertise, reshaping wealth creation. Our approach combines the stability of systematic investment monthly plans with the growth potential of real estate, offering investors a dual advantage. With a commitment to transparency, innovation, and client-centric values, we redefine investment, providing a platform that merges the best of SIMPs and real estate for lasting financial success.</p>
            </div>
            <div className="md:w-1/2 lg:w-1/3 aboutCard" style={{padding:'10px'}}>
                <h1 style={{textAlign:'center',fontSize:'30px',color:'#ccc918'}}>What we do </h1>
              <p style={{textAlign:'justify'}}> We are experts in managing funds effectively, smoothly handling the ups and downs of both the stock market and real estate. Our skilled team employs a smart strategy, utilizing market knowledge and creative investment methods to achieve the best results. In the stock market, we study trends, manage risks, and seize opportunities for strong financial success. Simultaneously, in real estate, we identify the best places to invest, ensuring a wise spread of our assets for steady growth. With careful research, risk control, and a dedication to maximizing value, we help our clients succeed in the always-changing worlds of both stocks and real estate.</p>
            </div>
            <div  className="md:w-1/2 lg:w-1/3 aboutCard" style={{padding:'10px'}}>
                <h1 style={{textAlign:'center',fontSize:'30px',color:'#ccc918',justifyContent:'space-between'}}>Vision</h1>
                <p style={{textAlign:'justify'}}> Our vision is to be a trusted guide for financial growth, seamlessly blending the worlds of investment funds and real estate. We aim to empower individuals and businesses to achieve their financial dreams by providing creative investment solutions and unlocking the full potential of real estate assets. Together, we aspire to build a financial world where we help people maximize their earnings and support their long-term happiness and success.</p>

</div>
            
       
            
           
        </div>
        </div>
        
    );
};

export default About;