import React, { useState, useEffect } from "react";
import "./Passbook.css";
import axios from "axios";

const Passbook = ({ userId, name }) => {
  const [passbookEntries, setPassbookEntries] = useState([]);
  const [editingEntry, setEditingEntry] = useState(null);
  const [newEntry, setNewEntry] = useState({ date: "", description: "", amount: "" });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Fetch passbook entries for the user from the backend
    const fetchPassbookEntries = async () => {
      setIsLoading(true);
      try {
        axios.defaults.withCredentials = true;
        const response = await axios.get("/passbook/" + userId);
        const data = response.data;
        setPassbookEntries(data);
      } catch (error) {
        console.error("Error fetching passbook entries:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPassbookEntries();
  }, [userId]);

  const handleEdit = (index) => {
    setEditingEntry(index);
  };

  const handleUpdate = async (index) => {
    setIsLoading(true);
    try {
      const updatedEntry = {
        ...passbookEntries[index],
        date: passbookEntries[index].date,
        description: passbookEntries[index].description,
        amount: passbookEntries[index].amount,
      };
      axios.defaults.withCredentials = true;
      await axios.put(`/passbook/${userId}/${index}`, updatedEntry);
      setEditingEntry(null);
      // Refresh the passbook entries after successful update
      const response = await axios.get("/passbook/" + userId);
      const data = response.data;
      setPassbookEntries(data);
    } catch (error) {
      console.error("Error updating passbook entry:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (index) => {
    setIsLoading(true);
    try {
      axios.defaults.withCredentials = true;
      await axios.delete(`/passbook/${userId}/${index}`);
      // Refresh the passbook entries after successful deletion
      const response = await axios.get("/passbook/" + userId);
      const data = response.data;
      setPassbookEntries(data);
    } catch (error) {
      console.error("Error deleting passbook entry:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewEntryChange = (e) => {
    setNewEntry({ ...newEntry, [e.target.name]: e.target.value });
  };

  const handleAddEntry = async () => {
    setIsLoading(true);
    try {
      axios.defaults.withCredentials = true;
      await axios.post(`/passbook/${userId}`, newEntry);
      setNewEntry({ date: "", description: "", amount: "" });
      // Refresh the passbook entries after successful addition
      const response = await axios.get("/passbook/" + userId);
      const data = response.data;
      setPassbookEntries(data);
    } catch (error) {
      console.error("Error adding new passbook entry:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="passbook-container">
      <h2>Passbook for <b>{name}</b></h2>
      {isLoading ? (
        <div className="loading">Loading...</div>
      ) : (
        <>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Amount</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {passbookEntries.map((entry, index) => (
                <tr key={index}>
                  <td>{editingEntry === index ? (
                    <input
                      type="text"
                      value={entry.date}
                      onChange={(e) => {
                        const updatedEntries = [...passbookEntries];
                        updatedEntries[index].date = e.target.value;
                        setPassbookEntries(updatedEntries);
                      }}
                    />
                  ) : (
                    entry.date
                  )}</td>
                  <td>{editingEntry === index ? (
                    <input
                      type="number"
                      value={entry.amount}
                      onChange={(e) => {
                        const updatedEntries = [...passbookEntries];
                        updatedEntries[index].amount = parseFloat(e.target.value);
                        setPassbookEntries(updatedEntries);
                      }}
                    />
                  ) : (
                    entry.amount
                  )}</td>
                  <td>
                    {editingEntry === index ? (
                      <>
                        <button onClick={() => handleUpdate(index)}>Save</button>
                        <button onClick={() => setEditingEntry(null)}>Cancel</button>
                      </>
                    ) : (
                      <>
                        <button onClick={() => handleEdit(index)}>Edit</button>
                        <button onClick={() => handleDelete(index)}>Delete</button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <br></br>
          <h3>New Entry</h3>
          <div className="new-entry-form">
            <input
              type="date"
              name="date"
              value={newEntry.date}
              placeholder="Date"
              onChange={handleNewEntryChange}
            />
            <input
              type="number"
              name="amount"
              value={newEntry.amount}
              placeholder="Amount"
              onChange={handleNewEntryChange}
            />
            <button onClick={handleAddEntry} disabled={isLoading}>
              {isLoading ? 'Adding...' : 'Add Entry'}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Passbook;