import React, { useState } from 'react';
import Header from '../Header/Header';
import axios from 'axios';
import './Login.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

const Login = ({setIsLoggedIn}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('/login-server', {
        username,
        password,
      });
      // Assuming your server returns a token upon successful login
      
      const type = response.data.userType;

      // Save the token in localStorage or session storage for future requests
      localStorage.setItem('type', type);
      setIsLoggedIn(true)
      // Redirect to the Profile page
      if(type=="admin"){
        navigate('/admin')
      }
      else{
        navigate('/profile');
      }
      
    } catch (error) {
      console.error('Login failed', error);
      // Handle login failure (e.g., show an error message)
    }
  };

  return (
    <div>
      <div className="flex items-center justify-center" style={{marginTop:'50px'}}>
        <div className="bg-white p-8 rounded shadow-md w-full max-w-sm">
          <h2 className="text-2xl font-bold mb-4">Login</h2>
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label htmlFor="username" className="block text-gray-700 text-sm font-bold mb-2">
                Username:
              </label>
              <input
                type="text"
                id="username"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="w-full border rounded py-2 px-3"
                placeholder="Enter your username"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2">
                Password:
              </label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full border rounded py-2 px-3"
                placeholder="Enter your password"
              />
            </div>
            <button
              type="submit"
              className="buttonn bg-black-500 text-white py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline-blue"
            >
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
