import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header = ({ scrollToAbout, scrollToTeam, scrollToContact, isLoggedIn}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-green-500  relative">
      <div className="container mx-auto flex justify-between items-center p-4">
        <div className="flex items-center">
          <Link to="/" className="text-white font-bold text-lg flex items-center">
            <img
              src={require('./logo.png')}
              alt="Your Logo"
              className="logo" // Set the logo height to 100% of the parent container (header)
            />
            <span className="ml-2">RDS Solution & Services</span>
          </Link>
        </div>
        <div className="hidden md:flex space-x-4">
          <Link to="/" className="text-white" onClick={closeMenu}>
            Home
          </Link>
          <Link to="/about" className="text-white" onClick={scrollToAbout}>
            About
          </Link>
          <Link to="/contact" className="text-white" onClick={scrollToContact}>
            Contact
          </Link>

         {!isLoggedIn&& <Link
            to="/login"
            className="text-white rounded-md border border-white px-3 py-1 hover:bg-white hover:text-gray-800 transition duration-300"
            onClick={closeMenu}
          >
            Login
          </Link>}

          {isLoggedIn&& <Link
            to="/profile"
            className="text-white"
            onClick={closeMenu}
          >
            Profile
          </Link>}

          {isLoggedIn&& <Link to="/lout" className="text-white" onClick={closeMenu}>
            Log Out
          </Link>}
        </div>
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              className="h-6 w-6"
            >
              {isMenuOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
              )}
            </svg>
          </button>
        </div>
      </div>
      {isMenuOpen && (
        <div className="md:hidden items-center bg-gray-800 justify-between font-medium w-full md:flex md:w-auto md:order-1 p-4">
          <Link
            to="/"
            className="block text-white mb-2 hover:text-gray-300 transition duration-300"
            onClick={closeMenu}
          >
            Home
          </Link>
          <Link
            to="/about"
            className="block text-white mb-2 hover:text-gray-300 transition duration-300"
            onClick={()=>{closeMenu();scrollToAbout()}}
          >
            About
          </Link>
          <Link
            to="/contact"
            className="block text-white mb-2 hover:text-gray-300 transition duration-300"
            onClick={()=>{closeMenu();scrollToContact()}}
          >
            Contact
          </Link>
          {!isLoggedIn&&  <Link
            to="/login"
            className="block text-white rounded-md border border-white px-3 py-1 hover:bg-white hover:text-gray-800 transition duration-300 md:w-auto"
            onClick={closeMenu}
          >
            Login
          </Link>}
          {isLoggedIn&&  <Link
            to="/profile"
            className="block text-white mb-2 hover:text-gray-300 transition duration-300"
            onClick={closeMenu}
          >
            Profile
          </Link>}

          {isLoggedIn&&  <Link
            to="/lout"
            className="block text-white mb-2 hover:text-gray-300 transition duration-300"
            onClick={closeMenu}
          >
            Log Out
          </Link>}
        </div>
      )}
    </header>
  );
};

export default Header;
