import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Legend } from 'chart.js';
import './SIPCalculator.css'
Chart.register(ArcElement);
Chart.register(Legend)

const SIPCalculator = () => {
    const investMax = 50000
    const investMin = 1000
    const rateMin = 1
    const rateMax = 50
    const periodMin = 1
    const periodMax = 50

    const [isSip,setSip]=useState(true)
    const [monthlyInvestment, setMonthlyInvestment] = useState(5000);
    const [returnRate, setReturnRate] = useState(24);
    const [timePeriod, setTimePeriod] = useState(3);
    const [investedAmount, setInvestedAmount] = useState(0);
    const [estimatedReturns, setEstimatedReturns] = useState(0);
    const [totalReturn, settotalReturns] = useState(0);
    function calculateSIPFutureValue(monthlyInvestment, returnRate, timePeriod) {
        const monthlyInterestRate = returnRate / 1200;
        const totalPeriods = 12 * timePeriod;

        const futureValue =
            monthlyInvestment *
            (((Math.pow(1 + monthlyInterestRate, totalPeriods) - 1) * (1 + monthlyInterestRate)) / monthlyInterestRate);

        return futureValue.toFixed(2); // Round to 2 decimal places
    }
    useEffect(() => {
        if (monthlyInvestment == 0 || returnRate == 0 || timePeriod == 0) {
            setInvestedAmount(0)
            setEstimatedReturns(0)
            settotalReturns(0)
            return
        }
        let invested = monthlyInvestment * 12 * timePeriod
        setInvestedAmount(invested)
        let estimated = Math.floor(calculateSIPFutureValue(monthlyInvestment, returnRate, timePeriod))
        setEstimatedReturns(estimated - invested)
        settotalReturns(estimated)
    }, [monthlyInvestment, returnRate, timePeriod])
    const handleMonthlyInvestmentChange = (event) => {

        if (!event.target.value || event.target.value == '') {
            setMonthlyInvestment(0)
            return
        }
        let value = parseInt(event.target.value, 10)
        if (value < 0) {
            setMonthlyInvestment(0)
        }
        else if (value > investMax) {
            setMonthlyInvestment(investMax)
        }
        else
            setMonthlyInvestment(parseInt(event.target.value, 10));
    };
    const handleReturnRateChange = (event) => {
        if (!event.target.value || event.target.value === '') {
            setReturnRate(0);
            return;
        }

        let value = parseInt(event.target.value, 10);

        if (value < 0) {
            setReturnRate(0);
        } else if (value > rateMax) {
            setReturnRate(rateMax);
        } else {
            setReturnRate(value);
        }
    };

    const handleTimePeriodChange = (event) => {
        if (!event.target.value || event.target.value === '') {
            setTimePeriod(0);
            return;
        }

        let value = parseInt(event.target.value, 10);

        if (value < 0) {
            setTimePeriod(0);
        } else if (value > periodMax) {
            setTimePeriod(periodMax);
        } else {
            setTimePeriod(value);
        }
    };
    const data = {
        labels: ['Estimated Returns', 'Invested Amount'],
        datasets: [
            {
                data: [(monthlyInvestment == 0 || returnRate == 0 || timePeriod == 0) ? 1 : estimatedReturns, (monthlyInvestment == 0 || returnRate == 0 || timePeriod == 0) ? 99 : investedAmount],
                backgroundColor: ['#FF6384', '#36A2EB'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB'],
            },
        ],
    };

    const options = {
        cutout: '70%',
    };





/////////lumpsum



const investMax1 = 50000000;
const investMin1 = 5000;
const rateMin1 = 1;
const rateMax1 = 50;
const periodMin1 = 1;
const periodMax1 = 50;

const [monthlyInvestment1, setMonthlyInvestment1] = useState(50000);
const [returnRate1, setReturnRate1] = useState(24);
const [timePeriod1, setTimePeriod1] = useState(3);
const [investedAmount1, setInvestedAmount1] = useState(0);
const [estimatedReturns1, setEstimatedReturns1] = useState(0);
const [totalReturn1, setTotalReturns1] = useState(0);

function calculateSIPFutureValue1(monthlyInvestment1, returnRate1, timePeriod1) {
    const monthlyInterestRate1 = returnRate1 / 100;
    const totalPeriods1 = timePeriod1;

    const futureValue1 =
        monthlyInvestment1 * Math.pow(1 + monthlyInterestRate1, totalPeriods1);

    return futureValue1.toFixed(2); // Round to 2 decimal places
}

useEffect(() => {
    if (monthlyInvestment1 === 0 || returnRate1 === 0 || timePeriod1 === 0) {
        setInvestedAmount1(0);
        setEstimatedReturns1(0);
        setTotalReturns1(0);
        return;
    }
    let invested = monthlyInvestment1 ;
    setInvestedAmount1(invested);
    let estimated = Math.floor(calculateSIPFutureValue1(monthlyInvestment1, returnRate1, timePeriod1));
    setEstimatedReturns1(estimated - invested);
    setTotalReturns1(estimated);
}, [monthlyInvestment1, returnRate1, timePeriod1]);

const handleMonthlyInvestmentChange1 = (event) => {
    if (!event.target.value || event.target.value === '') {
        setMonthlyInvestment1(0);
        return;
    }
    let value = parseInt(event.target.value, 10);
    if (value < 0) {
        setMonthlyInvestment1(0);
    } else if (value > investMax1) {
        setMonthlyInvestment1(investMax1);
    } else {
        setMonthlyInvestment1(parseInt(event.target.value, 10));
    }
};

const handleReturnRateChange1 = (event) => {
    if (!event.target.value || event.target.value === '') {
        setReturnRate1(0);
        return;
    }

    let value = parseInt(event.target.value, 10);

    if (value < 0) {
        setReturnRate1(0);
    } else if (value > rateMax1) {
        setReturnRate1(rateMax1);
    } else {
        setReturnRate1(value);
    }
};

const handleTimePeriodChange1 = (event) => {
    if (!event.target.value || event.target.value === '') {
        setTimePeriod1(0);
        return;
    }

    let value = parseInt(event.target.value, 10);

    if (value < 0) {
        setTimePeriod1(0);
    } else if (value > periodMax1) {
        setTimePeriod1(periodMax1);
    } else {
        setTimePeriod1(value);
    }
};

const data1 = {
    labels: ['Estimated Returns', 'Invested Amount'],
    datasets: [
        {
            data: [(monthlyInvestment1 === 0 || returnRate1 === 0 || timePeriod1 === 0) ? 1 : estimatedReturns1, (monthlyInvestment1 === 0 || returnRate1 === 0 || timePeriod1 === 0) ? 99 : investedAmount1],
            backgroundColor: ['#FF6384', '#36A2EB'],
            hoverBackgroundColor: ['#FF6384', '#36A2EB'],
        },
    ],
};


//////////////////////////////////////////////




    return (
        <div className='flex flex-wrap'>


            {isSip &&<div className='flex flex-wrap' style={{justifyContent:'center',flex:1}}>
                <div className='flex flex-wrap sip' style={{flex:1,justifyContent:'center', margin:'25px'}}>
                <div className='lg:w-3/4' style={{paddingRight:'5vw'}}>
                    <div style={{display:'flex',textAlign:'center',marginBottom:'15px'}}>
                    <button style={{padding:'5px',borderRadius:'5px',backgroundColor: isSip ? 'green' : 'white',color: isSip ? 'white' : 'black', width:'100px',margin:'5px',fontSize:'20px',fontWeight:'bold'}} onClick={()=>{setSip(!isSip)}}>SIMP</button>
                    <button style={{padding:'5px',borderRadius:'5px',backgroundColor: isSip ? 'white' : 'green',color: isSip ? 'black' : 'white',width:'120px',margin:'5px',fontSize:'20px',fontWeight:'bold'}} onClick={()=>{setSip(!isSip)}}>LumpSum</button>
                    </div>


                   
                    <div style={{ display: 'flex', justifyContent: 'space-between', fontSize:'20px'}}>
                        <p>Monthly Investment</p>
                        <div style={{maxWidth:'30%',backgroundColor:'#ebfaeb',color:'#0f014d',height:'35px'}}>
                            ₹
                            <input
                                value={monthlyInvestment}
                                onChange={handleMonthlyInvestmentChange}
                                style={{
                                    textAlign:'right',
                                    outline:'none',
                                    border:'none',
                                    background: 'transparent',
                                    maxWidth: '200px',
                                    width:'calc(100% - 20px)'
                                  }}
                            />
                        </div>
                    </div>
                    <input
                        style={{width:'100%',maxWidth:'500px',marginBottom:'20px',marginTop:'20px'}}
                        type="range"
                        id="monthlyInvestmentSlider"
                        name="monthlyInvestmentSlider"
                        min={investMin}
                        max={investMax}
                        value={monthlyInvestment}
                        step="100"
                        onChange={handleMonthlyInvestmentChange}
                    />

                    {/* Expected Return Rate */}
                    <div style={{ display: 'flex', justifyContent: 'space-between', fontSize:'20px'}}>
                        <p>Expected Return Rate (p.a.)</p>
                        <div style={{maxWidth:'30%',backgroundColor:'#ebfaeb',color:'#0f014d',height:'35px'}}>
                            
                            <input
                                value={returnRate}
                                onChange={handleReturnRateChange}
                                style={{
                                    textAlign:'right',
                                    outline:'none',
                                    border:'none',
                                    background: 'transparent',
                                    maxWidth: '200px',
                                    width:'calc(100% - 20px)'
                                  }}
                            />
                            %
                        </div>
                    </div>
                    <input
                        style={{width:'100%',maxWidth:'500px',marginBottom:'20px',marginTop:'20px'}}
                        type="range"
                        id="returnRateSlider"
                        name="returnRateSlider"
                        min={rateMin}
                        max={rateMax}
                        value={returnRate}
                        step="1"
                        onChange={handleReturnRateChange}
                    />

                    {/* Time Period */}
                    <div style={{ display: 'flex', justifyContent: 'space-between', fontSize:'20px'}}>
                        <p>Time Period</p>
                        <div style={{maxWidth:'30%',backgroundColor:'#ebfaeb',color:'#0f014d',height:'35px'}}>
                    
                            <input
                                value={timePeriod}
                                onChange={handleTimePeriodChange}
                                style={{
                                    outline:'none',
                                    border:'none',
                                    textAlign:'right',
                                    background: 'transparent',
                                    maxWidth: '200px',
                                    width:'calc(100% - 30px)'
                                  }}
                            />
                            Yr
                        </div>
                    </div>
                    <input
                        style={{width:'100%',maxWidth:'500px',marginBottom:'20px',marginTop:'20px'}}
                        type="range"
                        id="timePeriodSlider"
                        name="timePeriodSlider"
                        min={periodMin}
                        max={periodMax}
                        value={timePeriod}
                        step="1"
                        onChange={handleTimePeriodChange}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between',fontWeight:'bold',marginBottom:'5px' }}>
                        <p>Invested Amount</p>
                        <div style={{}}>
                            ₹{investedAmount}
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between',fontWeight:'bold' ,marginBottom:'5px'}}>
                        <p>Estimated Returns</p>
                        <div style={{}}>
                            ₹{estimatedReturns}
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between',fontWeight:'bold',marginBottom:'5px' }}>
                        <p>Total Amount</p>
                        <div style={{}}>
                            ₹{totalReturn}
                        </div>
                    </div>
                </div>
                <div className='lg:w-1/4' style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                    <Doughnut data={data} options={{
                        cutout: '70%',
                        plugins: {
                            legend: {
                                display: true,
                                position: 'top',
                                onClick: null // You can change the position to 'bottom', 'left', 'right'
                            },
                        },
                    }} />
                </div>
                </div>
            </div>}
            {!isSip &&<div className='flex flex-wrap' style={{ justifyContent: 'center', flex: 1 }}>
    <div className='flex flex-wrap sip' style={{ flex: 1, justifyContent: 'center', margin: '25px' }}>
        <div className='lg:w-3/4' style={{ paddingRight: '5vw' }}>
            <div style={{ display: 'flex', textAlign: 'center', marginBottom: '15px' }}>
                <button style={{ padding: '5px', borderRadius: '5px', backgroundColor: isSip ? 'green' : 'white', color: isSip ? 'white' : 'black', width: '100px', margin: '5px', fontSize: '20px', fontWeight: 'bold' }} onClick={() => { setSip(!isSip) }}>SIMP</button>
                <button style={{ padding: '5px', borderRadius: '5px', backgroundColor: isSip ? 'white' : 'green', color: isSip ? 'black' : 'white', width: '120px', margin: '5px', fontSize: '20px', fontWeight: 'bold' }} onClick={() => { setSip(!isSip) }}>LumpSum</button>
            </div>

            {/* Monthly Investment */}
            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '20px' }}>
                <p>Invested Value</p>
                <div style={{ maxWidth: '40%', backgroundColor: '#ebfaeb', color: '#0f014d', height: '35px' }}>
                    ₹
                    <input
                        value={monthlyInvestment1}
                        onChange={handleMonthlyInvestmentChange1}
                        style={{
                            textAlign: 'right',
                            outline: 'none',
                            border: 'none',
                            background: 'transparent',
                            maxWidth: '200px',
                            width: 'calc(100% - 20px)'
                        }}
                    />
                </div>
            </div>
            <input
                style={{ width: '100%', maxWidth: '500px', marginBottom: '20px', marginTop: '20px' }}
                type="range"
                id="monthlyInvestmentSlider"
                name="monthlyInvestmentSlider"
                min={investMin1}
                max={investMax1}
                value={monthlyInvestment1}
                step="100"
                onChange={handleMonthlyInvestmentChange1}
            />

            {/* Expected Return Rate */}
            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '20px' }}>
                <p>Expected Return Rate (p.a.)</p>
                <div style={{ maxWidth: '40%', backgroundColor: '#ebfaeb', color: '#0f014d', height: '35px' }}>
                    <input
                        value={returnRate1}
                        onChange={handleReturnRateChange1}
                        style={{
                            textAlign: 'right',
                            outline: 'none',
                            border: 'none',
                            background: 'transparent',
                            maxWidth: '200px',
                            width: 'calc(100% - 20px)'
                        }}
                    />
                    %
                </div>
            </div>
            <input
                style={{ width: '100%', maxWidth: '500px', marginBottom: '20px', marginTop: '20px' }}
                type="range"
                id="returnRateSlider"
                name="returnRateSlider"
                min={rateMin1}
                max={rateMax1}
                value={returnRate1}
                step="1"
                onChange={handleReturnRateChange1}
            />

            {/* Time Period */}
            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '20px' }}>
                <p>Time Period</p>
                <div style={{ maxWidth: '40%', backgroundColor: '#ebfaeb', color: '#0f014d', height: '35px' }}>
                    <input
                        value={timePeriod1}
                        onChange={handleTimePeriodChange1}
                        style={{
                            outline: 'none',
                            border: 'none',
                            textAlign: 'right',
                            background: 'transparent',
                            maxWidth: '200px',
                            width: 'calc(100% - 30px)'
                        }}
                    />
                    Yr
                </div>
            </div>
            <input
                style={{ width: '100%', maxWidth: '500px', marginBottom: '20px', marginTop: '20px' }}
                type="range"
                id="timePeriodSlider"
                name="timePeriodSlider"
                min={periodMin1}
                max={periodMax1}
                value={timePeriod1}
                step="1"
                onChange={handleTimePeriodChange1}
            />

            {/* Invested Amount */}
            <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', marginBottom: '5px' }}>
                <p>Invested Amount</p>
                <div style={{}}>
                    ₹{investedAmount1}
                </div>
            </div>

            {/* Estimated Returns */}
            <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', marginBottom: '5px' }}>
                <p>Estimated Returns</p>
                <div style={{}}>
                    ₹{estimatedReturns1}
                </div>
            </div>

            {/* Total Amount */}
            <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', marginBottom: '5px' }}>
                <p>Total Amount</p>
                <div style={{}}>
                    ₹{totalReturn1}
                </div>
            </div>
        </div>
        <div className='lg:w-1/4' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {/* Update Doughnut component with the correct data prop */}
            <Doughnut data={data1} options={{
                cutout: '70%',
                plugins: {
                    legend: {
                        display: true,
                        position: 'top',
                        onClick: null // You can change the position to 'bottom', 'left', 'right'
                    },
                },
            }} />
        </div>
    </div>
</div>
}
            
        </div>
    );
};

export default SIPCalculator;
