// src/components/ResponsiveComponent.js

import React from 'react';
import { Link } from 'react-router-dom';

const Middle = () => {
    return (
        <div className="relative bg-gray-800 text-white">
           
            <div className='findHere'>
                <p style={{fontSize: '6vw',fontWeight: 'bold'}} className='btxt'>
                    <span style={{color:'#5c0982'}}>FIND</span><span style={{color:'green'}}>HERE</span>
                </p>
                <p style={{fontSize:'2.5vw',color:'black'}} className='atxt'>
                YOUR BEST FINANCIAL FRIEND
                </p>
                <p style={{fontSize:'4vw',color:'black',fontWeight: 'bold'}} className='ctxt'>
                TODAY
                </p>
                <p style={{fontSize:'4vw',color:'#5c0982',fontWeight: 'bold' }} className='ctxt'>
                TOMORROW
                </p>
                <p style={{fontSize:'4vw',color:'black',fontWeight: 'bold'}} className='ctxt'>
                AND ALWAYS
                </p>

            </div>
            <Link to='/login'>
            <div className='findHere1'>
                <p>Get Started</p>
            </div>
            </Link>
            <img
                src={require('./main.png')}
                alt="Cover Image"
                className="middle-img h-64 md:h-96 lg:h-128 xl:h-160 2xl:h-192"
            />
        </div>
    );
};

export default Middle;
